import React from "react";
import { STATES } from "@upsolve/shared";
import { Table } from "@upsolve/ui";

import trustees from "../../../content/trustees.json";
import { TH } from "../Tables/TableCells";
import { H5 } from "../Type/Display.h";

const TrusteesList = ({ district, state }) => {
  if (district == null && state == null) return null;

  const filteredTrustees = trustees.filter((trustee) => {
    if (state != null) return new RegExp(`^${state.toLowerCase()}`).test(trustee.district);
    return false;
  });

  return (
    <Table size="sm">
      <thead>
        <tr>
          <TH colSpan="2" className="header">
            <H5>{STATES[state.toUpperCase()]} Trustees</H5>
          </TH>
        </tr>
        <tr>
          <TH>Trustee</TH>
          <TH>Contact Info</TH>
        </tr>
      </thead>
      <tbody>
        {Array.prototype.map.call(filteredTrustees, (trustee, index) => (
          <tr key={index}>
            <td>{trustee.name}</td>
            <td>
              {trustee.email}
              <br />
              {trustee.phoneNumber}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TrusteesList;
