import { get, kebabCase } from "lodash";
import styled from "styled-components";
import React, { useState } from "react";
import { A, P, Small, Table } from "@upsolve/ui";

import getConstantComponentValue from "./getConstantComponentValue";
import * as HEADERS from "../Type/Display.h";
import StateMedianIncomeChart from "../Programmatic/StateMedianIncomeChart";
import StatePovertyLevelsChart from "../Programmatic/StatePovertyLevelsChart";
import DistrictRequirementsSection from "../Programmatic/DistrictRequirementsSection";
import LegalAidOrgsList from "../Programmatic/LegalAidOrgsList";
import CourtsList from "../Programmatic/CourtsList";
import JudgesList from "../Programmatic/JudgesList";
import TrusteesList from "../Programmatic/TrusteesList";
import CTAInsertSimple from "../CTA/CTAInsertSimple";
import CTAInsertZipcode from "../CTA/CTAInsertZipcode";
import LegalServiceListing from "../Programmatic/LegalServiceListing";
import ImageInContext from "../Media/ImageInContext";
import HorizontalRule from "../Layout/HorizontalRule.hr";
import ContentSectionFooter from "../Layout/ContentSectionFooter";
import FAQPanel from "../Programmatic/FAQPanel";
import Callout from "../Programmatic/Callout";
import withViewportWatch from "../analytics/withViewportWatch";
import trackComponentAction from "../analytics/trackComponentAction";

const StyledImageWrapper = styled.div`
  text-align: center;
  & > img {
    ${(props) => {
      // If image is 2x taller than wide, don't set max height. It's purposefully tall
      if (props.imageHeight > props.imageWidth) {
        return `
          padding: 0 5em;
          @media(max-width: ${props.theme.breakpoints[500]}) {
            padding: 1em;
          }
        `;
      }
      return "max-height: 360px;";
    }}
  }
`;

// Getting the ratio of a iframe height/width and then multipling it by .content-body__container width
// TODO: Instead of using 750, 650 placeholders, should be grabbing the actual width
const StyledIFramWrapper = styled.div`
  height: ${(props) => `${(props.height / props.width) * 750}px`};
  width: 100%;
  max-height: ${(props) => `${props.height}px`};
  max-width: ${(props) => `${props.width}px`} !important;
  overflow: hidden;
  margin: 1em auto 2em;
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    height: ${(props) => `${(props.height / props.width) * 400}px`};
    margin: 2em auto;
  }
`;

// Render iframe when it's in view so we cut down on initial load
const RichTextNodeIFrame = withViewportWatch((props) => {
  const [isIframeVisible, setIframeIsVisible] = useState(false);
  if (props.isInViewport && !isIframeVisible) setIframeIsVisible(true);
  return isIframeVisible ? (
    <StyledIFramWrapper height={props.iframe.height} width={props.iframe.width}>
      <iframe
        src={props.iframe.src}
        title={props.iframe.title}
        height={props.iframe.height}
        width={props.iframe.width}
      />
    </StyledIFramWrapper>
  ) : (
    <div />
  );
});

export default function richTextNodeToComponent(node, nodeIndex, nodeArray, context) {
  if (node == null) return null;
  const locale = "en-US";
  const { data, content, marks, nodeType, value } = node;
  const locality = get(context, "locality");

  // If all text, concat elements without marks
  const fullText =
    node.content != null && node.content.find((c) => c.nodeType !== "text") == null
      ? node.content.reduce((acc, c) => `${acc}${c.value}`, "")
      : null;

  // If document type, idk, just return content
  if (nodeType === "document") {
    return Array.prototype.map.call(node.content, (n, i, a) => richTextNodeToComponent(n, i, a, context));
  }

  // HTML Elements
  // - Text
  if (nodeType === "text" && value && value !== "") {
    if (marks.find((m) => m.type === "bold") != null && marks.find((m) => m.type === "italic")) {
      return (
        <b key={nodeIndex}>
          <i>{value}</i>
        </b>
      );
    }
    if (marks.find((m) => m.type === "bold") != null) {
      return <b key={nodeIndex}>{value}</b>;
    }
    if (marks.find((m) => m.type === "italic") != null) {
      return <i key={nodeIndex}>{value}</i>;
    }
    return value;
  }
  // - Links
  if (nodeType === "hyperlink") {
    let uri = data.uri || "";
    // Append CTA query param if for screener
    // TODO: change intro to bankruptcy-screener
    try {
      if (uri.includes(UPSOLVE_FILER_URL)) {
          const newUri = new URL(data.uri);
          uri = newUri.toString()
      }
    } catch (e) {
      console.warn(`Could not append CTA param to rich text link - ${e.message}`);
    }
    const trackingProps = {
      componentName: "RichTextNode",
      componentVersion: 0,
    };

    // Append blank target & rel if external
    // TODO: track logins and screeners and referral separately here
    return (
      <A
        href={uri}
        key={nodeIndex}
        onClick={() =>
          trackComponentAction({
            ...trackingProps,
            actionId: uri.includes("my.upsolve.org")
              ? "richTextNode.hyperlink.myUpsolveNavigation"
              : uri.includes("upsolve.org")
              ? "richTextNode.hyperlink.internal"
              : "richTextNode.hyperlink.external",
            actionMethod: "click",
          })
        }
        target={!uri.includes("upsolve.org") ? "_blank" : ""}
        rel={!uri.includes("upsolve.org") ? "noopener noreferrer" : ""}
      >
        {Array.prototype.map.call(content, (n, i, a) => richTextNodeToComponent(n, i, a, context))}
      </A>
    );
  }
  // - Headers
  if (nodeType.includes("heading-")) {
    // HACK: Keep h2/h3/etc... declerations but downsize style 1 level
    const headerLevel = Number(nodeType.slice(-1)) > 5 ? 5 : Number(nodeType.slice(-1));
    const H = HEADERS[`H${headerLevel + 1}`];
    return get(content, "[0].value") != null ? (
      <H as={`h${headerLevel}`} id={kebabCase(fullText)} key={nodeIndex}>
        {Array.prototype.map.call(node.content, (n, i, a) => richTextNodeToComponent(n, i, a, context))}
      </H>
    ) : null;
  }
  // - Paragraph
  if (
    nodeType === "paragraph" &&
    node.content.filter((c) => c.nodeType === "hyperlink" || (c.nodeType === "text" && c.value.length > 0)).length
  ) {
    return (
      <P key={nodeIndex}>{Array.prototype.map.call(content, (n, i, a) => richTextNodeToComponent(n, i, a, context))}</P>
    );
  }
  // - Lists
  if (nodeType === "list-item") {
    // Inner content is probably a paragraph
    // HACK: Dear God.
    return (
      <li key={nodeIndex}>
        {Array.prototype.map.call(content, (n, i, a) => richTextNodeToComponent(n, i, a, context))}
      </li>
    );
  }
  if (nodeType.includes("-list")) {
    const listItems = Array.prototype.map.call(content, (n, i, a) => richTextNodeToComponent(n, i, a, context));
    return nodeType.includes("unordered") ? <ul key={nodeIndex}>{listItems}</ul> : <ol key={nodeIndex}>{listItems}</ol>;
  }

  // Embeded Assets
  // - Images
  if (nodeType === "embedded-asset-block") {
    return get(data, "target.fields.file['en-US'].url") != null ? (
      <ImageInContext
        key={nodeIndex}
        alt={get(data, "target.fields.description['en-US']") || get(data, "target.fields.title['en-US']")}
        src={get(data, "target.fields.file['en-US'].url")}
      />
    ) : null;
  }

  // Inline Embeded Entries
  if (nodeType === "embedded-entry-inline") {
    // - Citation
    if (get(data, "target.sys.contentType.sys.id") === "citation") {
      return (
        <sup key={nodeIndex}>
          <A href={`#citation-${kebabCase(get(data, "target.fields.title['en-US']"))}`}>
            [{(context.citations || []).map((c) => c.title).indexOf(get(data, "target.fields.title['en-US']")) + 1}]
          </A>
        </sup>
      );
    }
    // - Constant Components
    if (get(data, "target.sys.contentType.sys.id") === "constantComponent") {
      try {
        if (
          getConstantComponentValue({ key: get(data, "target.fields.type['en-US']"), state: get(locality, "state") })
        ) {
          return getConstantComponentValue({
            key: get(data, "target.fields.type['en-US']"),
            state: get(locality, "state"),
          });
        } else {
          console.warn(`Missing constant: ${get(data, "target.fields.type['en-US']")}`);
          return null;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }

  // Embeded Entries
  if (nodeType === "embedded-entry-block") {
    // - Embeds/IFrames
    if (get(data, "target.sys.contentType.sys.id") === "embed") {
      // IFrame
      if (get(data, "target.fields.src['en-US']")) {
        return (
          <RichTextNodeIFrame
            key={nodeIndex}
            iframe={{
              src: get(data, "target.fields.src['en-US']"),
              title: get(data, "target.fields.title['en-US']"),
              height: get(data, "target.fields.height['en-US']"),
              width: get(data, "target.fields.width['en-US']"),
            }}
          />
        );
      }
    }
    // - Table
    if (get(data, "target.sys.contentType.sys.id") === "table" && get(data, "target.fields.table['en-US'].tableData")) {
      return (
        <Table size="sm" key={nodeIndex}>
          <thead>
            <tr>
              {get(data, "target.fields.table['en-US'].tableData")[0].map((tableRowHeader) => (
                <th key={tableRowHeader}>{tableRowHeader}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {get(data, "target.fields.table['en-US'].tableData")
              .slice(1)
              .map((tableRow, tableRowIndex) => (
                <tr key={tableRowIndex}>
                  {tableRow.map((tableRowItem, tableRowItemIndex) => {
                    // Check if intending to be a link
                    if (/\[(.+?)\]\((.+?)\)/.test(tableRowItem)) {
                      const [textRowFull, tableRowText, tableRowUrl] = /\[(.+?)\]\((.+?)\)/.exec(tableRowItem);
                      return (
                        <td key={tableRowItemIndex}>
                          <A href={tableRowUrl} target={tableRowUrl.includes("https://upsolve.org") ? "" : "_blank"}>
                            {tableRowText}
                          </A>
                        </td>
                      );
                    }
                    return <td key={tableRowItemIndex}>{tableRowItem}</td>;
                  })}
                </tr>
              ))}
          </tbody>
        </Table>
      );
    }
    // - Image
    if (get(data, "target.sys.contentType.sys.id") === "image") {
      return (
        <StyledImageWrapper
          key={nodeIndex}
          imageHeight={get(data, "target.fields.image['en-US'].fields.file['en-US'].details.image.height")}
          imageWidth={get(data, "target.fields.image['en-US'].fields.file['en-US'].details.image.width")}
        >
          <ImageInContext
            key={nodeIndex}
            alt={
              get(data, "target.fields.altText['en-US']") ||
              get(data, "target.fields.title['en-US']") ||
              get(data, "target.fields.caption['en-US']")
            }
            src={get(data, "target.fields.image['en-US'].fields.file['en-US'].url")}
          />
          {get(data, "target.fields.caption['en-US']") && (
            <div className="image-caption">
              <Small>{get(data, "target.fields.caption['en-US']")}</Small>
            </div>
          )}
        </StyledImageWrapper>
      );
    }
    // - Callout
    if (get(data, "target.sys.contentType.sys.id") === "callout") {
      const content = get(data, "target.fields.body['en-US']");
      return <Callout component={richTextNodeToComponent(content, 0, [], context)} />;
    }
    // - FAQ
    if (get(data, "target.sys.contentType.sys.id") === "faq") {
      return (
        <FAQPanel
          faqs={get(data, "target.fields.questions['en-US']", []).map((question) => ({
            question: get(question, "fields.text['en-US']"),
            component: richTextNodeToComponent(
              get(question, "fields.answers['en-US'][0].fields.text['en-US']"),
              0,
              [],
              context
            ),
          }))}
        />
      );
    }
    // - How To
    if (get(data, "target.sys.contentType.sys.id") === "howTo") {
      const H = HEADERS.H4;
      const steps = get(node, `data.target.fields.steps['en-US']`, []).map((step) => step.fields);
      return steps.length > 0 ? (
        <div key={nodeIndex}>
          <div>
            <ol>
              {Array.prototype.map.call(steps, (step, i) => (
                <li key={i}>
                  <a href={`#${kebabCase(step.title["en-US"])}`}>{step.title["en-US"]}</a>
                </li>
              ))}
            </ol>
          </div>
          <HorizontalRule />
          {Array.prototype.map.call(steps, (step, stepIndex) => {
            return (
              <div key={stepIndex} className="section">
                <H as="h3" id={kebabCase(step.title["en-US"])}>
                  {step.title["en-US"]}
                </H>
                {richTextNodeToComponent(get(step, "content['en-US']"), 0, [], context)}
              </div>
            );
          })}
        </div>
      ) : null;
    }
    // - Legal Service
    if (get(data, "target.sys.contentType.sys.id") === "legalService") {
      return (
        <LegalServiceListing
          businessName={get(data, `target.fields.businessName['${locale}']`)}
          firstName={get(data, `target.fields.firstName['${locale}']`)}
          lastName={get(data, `target.fields.lastName['${locale}']`)}
          email={get(data, `target.fields.email['${locale}']`)}
          phoneNumber={get(data, `target.fields.phoneNumber['${locale}']`)}
          image={get(data, `target.fields.image['${locale}'].fields.image['${locale}'].fields.file['${locale}'].url`)}
          address={{
            street1: get(data, `target.fields.address[${locale}].fields.street1[${locale}]`),
            street2: get(data, `target.fields.address[${locale}].fields.street2[${locale}]`),
            city: get(data, `target.fields.address[${locale}].fields.city[${locale}]`),
            state: get(data, `target.fields.address[${locale}].fields.state[${locale}]`),
            zipcode: get(data, `target.fields.address[${locale}].fields.zipcode[${locale}]`),
          }}
          biography={get(data, `target.fields.description['${locale}']`)}
        />
      );
    }
    // - Median Income Chart
    if (get(data, "target.fields.type['en-US']") === "stateMedianFamilyIncome") {
      return (
        <StateMedianIncomeChart
          key={nodeIndex}
          state={get(data, "target.fields.state['en-US']") || get(locality, "state")}
        />
      );
    }
    // - Poverty Levels Chart
    if (get(data, "target.fields.type['en-US']") === "statePovertyLevel") {
      return (
        <StatePovertyLevelsChart
          key={nodeIndex}
          state={get(data, "target.fields.state['en-US']") || get(locality, "state")}
        />
      );
    }
    // - District Requirements
    if (get(data, "target.fields.type['en-US']") === "districtRequirements") {
      const districtAbbrev = get(data, "target.fields.district['en-US']");
      return (
        <DistrictRequirementsSection
          key={nodeIndex}
          district={(context.districts || []).find((district) => district.abbrev === districtAbbrev)}
        />
      );
    }
    // - Legal Aid Org List
    if (get(data, "target.fields.type['en-US']") === "stateLegalAidOrganizations") {
      return (
        <LegalAidOrgsList key={nodeIndex} state={get(data, "target.fields.state['en-US']") || get(locality, "state")} />
      );
    }
    // - Courts List
    if (get(data, "target.fields.type['en-US']") === "stateCourts") {
      return <CourtsList key={nodeIndex} state={get(data, "target.fields.state['en-US']") || get(locality, "state")} />;
    }
    if (get(data, "target.fields.type['en-US']") === "districtCourts") {
      return <CourtsList key={nodeIndex} district={get(data, "target.fields.district['en-US']")} />;
    }
    // - Judges List
    if (get(data, "target.fields.type['en-US']") === "stateJudges") {
      return <JudgesList key={nodeIndex} state={get(data, "target.fields.state['en-US']") || get(locality, "state")} />;
    }
    if (get(data, "target.fields.type['en-US']") === "districtJudges") {
      return <JudgesList key={nodeIndex} district={get(data, "target.fields.district['en-US']")} />;
    }
    // - Trustees List
    if (get(data, "target.fields.type['en-US']") === "stateTrustees") {
      return (
        <TrusteesList key={nodeIndex} state={get(data, "target.fields.state['en-US']") || get(locality, "state")} />
      );
    }
    // - CTA Simple
    if (get(data, "target.fields.type['en-US']") === "ctaSimpleInsert") {
      return (
        <CTAInsertSimple
          key={nodeIndex}
          state={get(data, "target.fields.state['en-US']") || get(locality, "state")}
          trackingPageContext={context.trackingPageContext}
        />
      );
    }
    // - CTA Zipcode
    if (get(data, "target.fields.type['en-US']") === "ctaZipcode") {
      return (
        <CTAInsertZipcode
          key={nodeIndex}
          state={get(data, "target.fields.state['en-US']") || get(locality, "state")}
          trackingPageContext={context.trackingPageContext}
        />
      );
    }
  }

  return null;
}
